<template>
  <div class="wrapper">
    <TwemojiGrinningFaceWithSweat class="icon" />
    <div class="copy">
      <h1>Aw shucks!</h1>
      <p>
        This page doesn't seem to exist. Sorry about that! If you think this page <i>should</i> exist,
        <span><router-link to="/contact">please let us know</router-link>.</span>
      </p>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
    margin: 5em auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.icon {
    font-size: 8em;
}

.copy {
    margin-top: 1em;
}
</style>
